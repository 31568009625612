<template>
  <div>
    <div class="row">
      <!-- Dashboard Profile yekannn -->
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <div class="card">
          <div class="card-header card-header-flex flex-column">
            <div class="d-flex flex-wrap pt-3 pb-4">
              <div class="text-dark font-size-18 font-weight-bold">
                Last Customer Visit
              </div>
              <!-- <div class="mr-5">
              <div class="text-gray-6">
                {{ user.role ? user.role : "Gak punya role" }}
              </div>
            </div>
            <div class="mr-5 text-center">
              <div class="text-dark font-size-18 font-weight-bold">
                {{ user.permissions.length }}
              </div>
              <div class="text-gray-6">Permissions</div>
            </div>
            <div class="mr-5 text-center">
              <i
                class="fa fa-undo"
                :class="{ 'rotate-center': isRotate }"
                @click="getLog"
                style="font-size: 40px; cursor: pointer;"
                aria-hidden="true"
              ></i>
            </div> -->
            </div>
          </div>

          <!-- content nya disini -->
          <div class="card-body">
            <vue-custom-scrollbar style="height: 500px;">
              <a-list
                item-layout="vertical"
                size="large"
                :data-source="dataall"
              >
                <div slot="footer"><b>Ini </b> footer</div>
                <a-list-item
                  slot="renderItem"
                  key="item.title"
                  slot-scope="item"
                >
                  <!-- <template slot="actions">
              </template> -->
                  <div class="row">
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <a-list-item-meta>
                        <template slot="description">
                          Kunjungan AO {{ item.nama_ao }} kepada nasabah
                          {{ item.nama_nasabah }}
                        </template>
                        <a slot="title" :href="item.href">{{ item.nama_ao }} - {{ convRelative(item.time) }}</a>
                        <a-avatar slot="avatar" :src="item.img_ao" />
                      </a-list-item-meta>
                      {{ item.keterangan }}
                      <div class="mt-3">
                        <span>
                          <a-icon type="like-o" style="margin-right: 8px;" />
                          {{ item.like }}
                        </span>
                        <a-divider class="mr-2 ml-2" type="vertical" />
                        <span>
                          <a-icon type="audit" style="margin-right: 8px;" />
                          Kunjungan ke {{ item.kunjungan_ke }}
                        </span>
                        <a-divider class="mr-2 ml-2" type="vertical" />
                        <span @click="openmodalreplies(item.comment)" style="cursor: pointer;">
                          <a-icon type="message" style="margin-right: 8px;" />
                          {{ item.comment.length }}
                        </span>
                      </div>
                      <strong class="mt-2" @click="openmodalreplies(item.comment)" style="cursor: pointer;"
                        >Comments</strong
                      >
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <img width="272" alt="logo" :src="item.img_kunjungan" @click="zoomGambar(item, 'img_kunjungan', 'nama_ao')" style="cursor: pointer;"/>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div
                        class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        v-for="(replies, index) in item.comment"
                        :key="index"
                      >
                        <div v-if="index <= 2">
                          <strong class="mr-2">{{ replies.nama_ao || "" }}</strong>
                          <label style="margin: 0 !important">{{ replies.comment }}</label>
                        </div>
                        <div v-if="index <= 2 && item.comment.length - 3 > 0">
                          <strong class="mr-2" @click="openmodalreplies(item.comment)" style="cursor: pointer;"
                            >See
                            {{
                              item.comment.length === 0
                                ? 0
                                : item.comment.length - 3
                            }}
                            more comments</strong
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </a-list-item>
              </a-list>
            </vue-custom-scrollbar>
          </div>
          <!-- akhiran content -->
        </div>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <a-card title="List AO">
          <div class="demo-infinite-container" style="padding: 1rem 1.6rem;">
            <a-list size="small" :data-source="dataAC">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta :key="index" description="">
                  <strong slot="title">{{ item.nama_ao }}</strong>
                  <a-avatar slot="avatar" :src="item.img_url" />
                </a-list-item-meta>
              </a-list-item>
              <!-- <div slot="header">
              Header
            </div>
            <div slot="footer">
              Footer
            </div> -->
            </a-list>
          </div>
        </a-card>
      </div>
    </div>
    <a-modal v-model="modalreplies.visible" title="Comments" :dialogStyle="{ top: modalreplies.commentdata.length >= 3 ? '30px' : '' }">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="modalreplies.visible = false;">
          Cancel
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-list
            :locale="{
              emptyText: 'No one has commented yet'
            }"
            class="comment-list"
            item-layout="horizontal"
            :data-source="modalreplies.commentdata"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-comment :author="item.nama_ao || ''" :avatar="item.img_url">
                <template slot="actions">
                  <span>
                    <a-icon type="like-o" style="margin-right: 8px;" />
                    {{ item.like || 0 }}
                  </span>
                  <span>
                    <a-icon type="like-o" style="margin-right: 8px;" />
                    {{ item.dislike || 0 }}
                  </span>
                  <span>
                    <a-icon type="message" style="margin-right: 8px;" />
                    {{ item.comment.length }}
                  </span>
                  <span>
                    {{ convRelative(item.time) }}
                  </span>
                </template>
                <p slot="content">
                  {{ item.comment }}
                </p>
                <!-- <a-tooltip slot="datetime" :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
                          <span>{{ item.datetime.fromNow() }}</span>
                        </a-tooltip> -->
              </a-comment>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-modal>
    <zoomgambar ref="czoomgambar" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import zoomgambar from '@/components/lou/zoomgambar'
import moment from 'moment'
import vueCustomScrollbar from 'vue-custom-scrollbar'
// import KitGeneral15 from './component/general15.vue'

export default {
  components: {
    vueCustomScrollbar,
    zoomgambar,
  },
  data() {
    return {
      modalreplies: {
        visible: false,
        commentdata: [],
      },
      dataall: [],
      pagination: {
        onChange: (page) => {
          console.log(page)
        },
        pageSize: 3,
      },
      actions: [
        { type: 'star-o', text: '156' },
        { type: 'like-o', text: '156' },
        { type: 'message', text: '2' },
      ],
      dataAC: [],
      mydata: [],
      publicpost: 0,
      eventslur: '',
      tgl: null,
      time: '',
      mypost: 0,
      activeKey: '1',
      isRotate: false,
      loading: false,
      open: false,
      visiblepopover: false,

      componentKey: 0,
    }
  },
  created() {
    this.datenow = lou.datenow()
    this.user = this.$store.state.user
    this.tgl = this.datenow
    this.getLog()
  },
  methods: {
    moment,
    zoomGambar(record, column, caption) {
      var data = {
        imageUrl: record[column],
        caption: record[caption],
      }
      this.$refs.czoomgambar.lihatgambar(data)
    },
    openmodalreplies(comment) {
      this.modalreplies.visible = true
      this.modalreplies.commentdata = comment
    },
    callback: function (key) {
      this.activeKey = key
    },
    hide() {
      this.visiblepopover = false
    },
    handleOpenChange(open) {
      this.open = open
    },
    // onChange(value, dateString) {
    //   console.log('Selected Time: ', value)
    //   this.tgl = value.format('YYYY-MM-DD HH:mm:ss')
    //   console.log('Formatted Selected Time: ', dateString)
    // },
    onChange(time, timeString) {
      this.time = timeString
      if (time === null) {
        this.getLog()
      }
    },
    async getLog() {
      // console.log('this.tgl', this.tgl)
      this.open = false
      this.isRotate = true
      this.loading = true
      this.componentKey += 1
      var resd = await lou.customUrlGet('loc/dashboard', false, true)
      if (resd) {
        this.dataAC = resd.data.ao
        this.dataall = resd.data.Kunjungan
        this.publicpost = this.dataall.length
      }
      // for (let i = 0; i < 10; i++) {
      //   var data = {
      //     id: 1,
      //     ao_id: 1,
      //     nama_ao: 'Ahmad Solihun',
      //     img_ao:
      //       'https://www.getroadmap.com/wp-content/uploads/2019/01/final-1.png',
      //     nasabah_id: 85,
      //     nama_nasabah: 'HARIYONO',
      //     kelurahan: 'Pisangcandi',
      //     kecamatan: 'Sukun',
      //     kunjungan_ke: 3,
      //     like: 2,
      //     keterangan:
      //       'Alhamdulillah, usahanya pak Hariyono berangsur pulih. Meskipun belum total, tapi lumayan untuk rencana angsuran bulan depan.',
      //     img_kunjungan:
      //       'https://cdn.antaranews.com/cache/800x533/2020/05/28/Dua-nasabah-BRI.jpg',
      //     time: '2021-10-10T10:25:01',
      //     comment: [
      //       {
      //         id: 1,
      //         kunjungan_id: 1,
      //         ao_id: 2,
      //         comment: 'wah keren, bro!!',
      //         like: 0,
      //         time: null,
      //       },
      //       {
      //         id: 2,
      //         kunjungan_id: 1,
      //         ao_id: 3,
      //         comment: 'Lanjutkan teman!!',
      //         like: 0,
      //         time: '2021-10-22T10:54:07',
      //       },
      //     ],
      //   }
      //   this.dataall.push(data)
      // }
      // var dataallbackup = this.$g.clone(this.dataall)
      // var mydata = this.dataall.filter((x) => x.user === this.user.name)
      // this.mypost = mydata.length
      // this.mydata = mydata
      this.loading = false
      this.isRotate = false
    },
    convRelative(date) {
      var ndate = date.replaceAll('T', ' ')
      return moment(ndate).startOf('hour').fromNow()
    },
  },
}
</script>

<style>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}
.rotate-center {
  -webkit-animation: rotate-center 0.5s linear infinite reverse both;
  animation: rotate-center 0.5s linear infinite reverse both;
}
/* ----------------------------------------------
 * Generated by Animista on 2020-9-23 15:33:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
